<template>
  <div>
    <b-table striped hover small responsive :items="notas" :fields="fields">
      <template #cell(data_pagamento)="data">
        <span>
          {{ data.item.data_pagamento | formataData }}
        </span>
      </template>
      <template #cell(valor)="data">
        <span>
          {{ data.item.valor | formataMoeda }}
        </span>
      </template>
      <template #row-details="row">
        <b-card border-variant="secondary">
          <div class="d-flex mb-2">
            <feather-icon
                class="text-success"
                icon="TrendingUpIcon"
                size="19"
            />
            <h5 class="mb-0 ml-50 text-success">Detalhes</h5>
          </div>
          <b-row class="mb-2">
            <b-col md="7">
              <strong>Link: </strong>
              <span>
                {{ row.item.nfiscal_link }}
              </span>
            </b-col>
            <b-col>
              <strong>Sistema: </strong>
              <span>
                {{ row.item.sistema }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <strong>ID Nota Fiscal: </strong>
              <span>
                {{ row.item.nfiscal_id }}
              </span>
            </b-col>
            <b-col>
              <strong>ID Pagamento: </strong>
              <span>
                {{ row.item.payment_id }}
              </span>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="7">
              <strong>Data Vencimento: </strong>
              <span>
                {{ row.item.data_vencimento | formataData }}
              </span>
            </b-col>
            <b-col>
              <strong>Data Pagamento: </strong>
              <span>
                {{ row.item.data_pagamento| formataData }}
              </span>
            </b-col>
          </b-row>

          <hr />

          <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
              class="my-1"
          >
            Fechar Detalhes
          </b-button>
        </b-card>
      </template>
      <template #cell(opcoes)="data">
        <b-button
            v-if="data.item.id"
            class="btn-icon"
            variant="flat-secondary"
            @click="data.toggleDetails"
            v-model="data.detailsShowing"
            style="padding: 2px"
        >
          <feather-icon
              :class="data.detailsShowing ? 'text-primary' : 'text-default'"
              icon="EyeIcon"
              size="16"
          />
        </b-button>


        <b-badge
            v-if="data.item.payment_id && data.item.status === 'erro'"
            variant="light-success"
            @click="emitirNfse(data.item)"
        >
          <b-spinner
              v-if="submit === data.item.id"
              small
              type="border"
              class="mr-2"
          />
          Emitir Nfse
        </b-badge>


        <b-badge
            v-if="data.item.id && (data.item.status === 'processando' || data.item.status === 'agendada')"
            variant="light-primary"
            @click="atualizarNfse(data.item)"
        >
          Atualizar
        </b-badge>

        <b-button
            v-if="data.item.id"
            class="btn-icon"
            variant="flat-secondary"
            @click="imprimir(data.item)"
            style="padding: 2px"
        >
          <feather-icon icon="PrinterIcon" size="16" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {BCard, BTable, BButton, BRow, BCol, BBadge, BSpinner} from "bootstrap-vue";
import { mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

export default {
  components: {
    BSpinner,
    BBadge,
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,

    ToastificationContent,
  },

  data() {
    return {
      submit: 0,
      assinatura: [],
      fields: [
        { key: "nfiscal_num", label: "NUM NF", thStyle: { width: "" } },
        { key: "id_company", label: "ID", thStyle: { width: "80PX" } },
        {
          key: "nome",
          label: "Cliente",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "data_pagamento",
          label: "Data Pagamento",
          sortable: true,
        },
        {
          key: "valor",
          label: "Valor",
          sortable: true,
        },
        {
          key: "opcoes",
          label: "Opções",
          thStyle: { width: "180px" },
          sortable: false,
        },
      ],
      statusBadges: {
        "processando": { name: "Processing", color: "light-primary" },
        "autorizada": { name: "Authorized", color: "light-success" },
        "negada": { name: "Denied", color: "light-danger" },
        "cancelada": { name: "Canceled", color: "light-warning" },
        "substituida": { name: "Substituted", color: "light-info" },
        "erro": { name: "Error", color: "light-danger" },
        "agendada": { name: "Scheduled", color: "light-secondary" },
      },
    };
  },
  computed: {
    ...mapState({
      notas: (state) => state.nota_fiscal.notas,
    }),
  },
  methods: {
    atualizarNfse(item) {
      
      if (!item.nfiscal_id) {
        this.mostrarToast("ID da NF-e não encontrado.", "error");
        return;
      }
      
      this.$http.put(`adm/nfse/${item.nfiscal_id}`).then((res) => {
        if (res.data) {
          this.mostrarToast("Status atualizado com Sucesso!", "success");
          this.$store.dispatch("nota_fiscal/listaNotas");
        }
      });
    },
    emitirNfse(item) {
      this.submit = item.id;


      if (!item.payment_id) {
        this.mostrarToast("ID do pagamento não encontrado.", "error");
        this.submit = 0;
        return;
      }

      this.$http.post(`adm/nfse/${item.payment_id}`).then((res) => {

        this.mostrarToast("Nota Fiscal Emitida com Sucesso! Aguarde 2 minutos e verifique o status", "success");

        setTimeout(() => {
          this.$store.dispatch("nota_fiscal/listaNotas");
        }, 2000)

      }).finally( () => {
        this.submit = 0;
      });
    },
    mostrarToast(mensagem, variante) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: mensagem,
          icon: "InfoIcon",
          variant: variante,
        },
      });
    },
    imprimir(item) {
      if (!item.nfiscal_link) return;
      window.open(item.nfiscal_link, "_blank");
    },
  },
  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  }
};
</script>

<style>
</style>
